import { Toast } from 'vant';

export default {
    // 消息提示
  msg(content) {
    Toast(content);

  },
  // 错误消息
  msgError(content) {
    Toast.fail(content);
  },
  // 成功消息
  msgSuccess(content) {
    Toast.success(content);
  },
  // 打开遮罩层
  loading(content) {
    Toast.loading({
        duration: 0,
        forbidClick: true,
        message: content,
      });
  },
  // 关闭遮罩层
  closeLoading() {
    Toast.clear();
  }
}