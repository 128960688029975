import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import store from "./store";

// 引入消息提示组件
import modal from './plugins/modal'

// 引入vant所需样式
import 'vant/lib/index.css';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';


// 以下皆为vant组件，在此进行全局注册
import { Button } from 'vant';
import { Loading } from 'vant';
import { Form, Field, CellGroup } from 'vant';
import { Sticky } from 'vant';
import { Cell } from 'vant';
import { Image as VanImage } from 'vant';
import { Grid, GridItem } from 'vant';
import { Badge } from 'vant';
import { Card } from 'vant';
import { Tag } from 'vant';
import { List } from 'vant';
import { NoticeBar } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Col, Row } from 'vant';
import { CountDown } from 'vant';
import { Switch } from 'vant';

const app = createApp(App)
app.use(router)
app.use(store)
app.use(Button);
app.use(Loading);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Sticky);
app.use(Cell);
app.use(VanImage);
app.use(Grid);
app.use(GridItem);
app.use(Badge);
app.use(Card);
app.use(Tag);
app.use(List);
app.use(NoticeBar);
app.use(RadioGroup);
app.use(Radio);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Col);
app.use(Row);
app.use(CountDown);
app.use(Switch);
app.mount('#app')

// 配置全局属性
app.config.globalProperties.$modal = modal;
