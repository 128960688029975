import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"
export default createStore({
    plugins:[
        createPersistedState({
            storage:window.localStorage
        })
    ],
    state: {
        userName: '',
        imagePath: '',
        realName: '',
        isLogin: false,
        userSource: '',
        backUrl: '',
        authoritys: '',
        paperName: ''
    },
    mutations: {
        setUserInfo(state, userInfo) {
            console.log(userInfo)
            state.userName = userInfo.userName
            state.imagePath = userInfo.imagePath
            state.realName = userInfo.realName
            state.isLogin = true
            state.userSource = userInfo.userSource
            state.authoritys = userInfo.authoritys
        },
        loginOut(state){
            state.userName = ''
            state.imagePath = ''
            state.realName = ''
            state.isLogin = false
        },
        setBackUrl(state, backUrl){
            state.backUrl = backUrl
        }
    }
});