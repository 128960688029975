import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    routes: [
        {
            // 路由到的地址
            path: '/',
            // 配置路由路径         
            component: () => import('../pages/Home.vue'),
            //组件名称
            name: 'Home'
        },
        {
            // 路由到的地址
            path: '/login',
            // 配置路由路径         
            component: () => import('../pages/Login.vue'),
            //组件名称
            name: 'Login'
        },
        {
            // 路由到的地址
            path: '/paper',
            // 配置路由路径         
            component: () => import('../pages/Paper.vue'),
            //组件名称
            name: 'Paper'
        },
        {
            // 路由到的地址
            path: '/answer',
            // 配置路由路径         
            component: () => import('../pages/Answer.vue'),
            //组件名称
            name: 'Answer'
        },
        {
            // 路由到的地址
            path: '/record',
            // 配置路由路径         
            component: () => import('../pages/Record.vue'),
            //组件名称
            name: 'Record'
        },
        {
            // 路由到的地址
            path: '/recordInfo',
            // 配置路由路径         
            component: () => import('../pages/RecordInfo.vue'),
            //组件名称
            name: 'RecordInfo'
        },
        {
            // 路由到的地址
            path: '/timeOut',
            // 配置路由路径
            component: () => import('../pages/TimeOut.vue'),
            //组件名称
            name: 'TimeOut'
        },
        {
            // 路由到的地址
            path: '/simulation',
            // 配置路由路径
            component: () => import('../pages/Simulation.vue'),
            //组件名称
            name: 'Simulation'
        }
    ],
    history: createWebHistory()
})
export default router;